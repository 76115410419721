import { createApp } from 'vue';
import { rtdbPlugin } from 'vuefire';
import App from './App.vue';
import router from './router';
import store from './store';

const vue = createApp(App).use(store).use(router);
vue.use(rtdbPlugin);

vue.mount('#app');
